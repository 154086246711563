import "./App.scss";
import { ChatContainer } from "./components/chatContainer.jsx";
import React from "react";

function App() {
  return (
      <div className="App">
      <ChatContainer />
    </div>
  );
}

export default App;
