import React from "react";

const EmojiIcon = () => (
  <svg
                      width={26}
                      height={26}
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M16.5 13a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3ZM12.998 19c1.893 0 3.486-1.477 3.99-3.492a.478.478 0 0 0-.057-.352.355.355 0 0 0-.12-.116.288.288 0 0 0-.15-.04H9.338a.288.288 0 0 0-.15.04.355.355 0 0 0-.119.116.477.477 0 0 0-.057.352c.5 2.015 2.093 3.492 3.986 3.492ZM9.5 13a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3Z"
                        fill="#264166"
                      />
                      <path
                        d="M13 22a9 9 0 1 1 0-18 9 9 0 0 1 0 18Z"
                        stroke="#264166"
                        strokeWidth={1.5}
                        strokeMiterlimit={10}
                      />
                    </svg>
);

export default EmojiIcon;