import React from "react";

const TelephoneIcon = ({color, className = ''}) => {
    return (<svg className={className} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" d="M10.9103 0.0525652C9.38635 0.19923 8.03692 0.585585 6.66019 1.2694C5.44271 1.87411 4.54863 2.51609 3.54412 3.50678C2.5792 4.45842 1.88565 5.4191 1.26645 6.66158C-0.200486 9.60519 -0.400224 12.9455 0.70467 16.0575C1.11456 17.2121 1.93594 18.6675 2.69009 19.5757C3.81741 20.9332 5.11745 21.9682 6.66019 22.7363C8.49091 23.6479 10.4358 24.0708 12.4265 23.9903C14.2143 23.9181 15.7668 23.5229 17.3485 22.7372C19.2522 21.7916 20.835 20.3943 22.0181 18.6145C22.4256 18.0016 23.0358 16.7978 23.2864 16.1127C24.1065 13.8702 24.225 11.2898 23.6145 8.97C23.3851 8.09845 23.1818 7.5622 22.7469 6.68155C22.1381 5.44873 21.4962 4.55135 20.5047 3.54692C18.0121 1.02188 14.4225 -0.285495 10.9103 0.0525652ZM7.86158 5.76945C8.01178 5.82534 10.2862 8.06798 10.3891 8.26167C10.5507 8.56602 10.5178 8.62191 9.66537 9.48811C9.23033 9.93022 8.83949 10.3601 8.7968 10.4435C8.61803 10.7924 8.65518 11.2124 8.89648 11.5713C9.67977 12.7362 11.7427 14.728 12.6935 15.2373C12.9107 15.3537 13.3375 15.3644 13.589 15.2598C13.6961 15.2152 14.0621 14.8909 14.5374 14.4194C15.3231 13.64 15.4442 13.5565 15.6984 13.6191C15.8594 13.6587 18.1619 15.9389 18.2693 16.1649C18.4131 16.4679 18.2537 16.7208 17.5079 17.3725C16.7115 18.0684 15.8249 18.3642 14.9106 18.2391C13.6869 18.0716 11.6769 17.0746 10.2218 15.9133C9.67733 15.4787 8.34314 14.141 7.9121 13.5975C6.94047 12.3723 6.09301 10.813 5.78524 9.68424C5.58672 8.95626 5.67111 8.19415 6.03198 7.4552C6.22764 7.0545 6.31053 6.94764 6.85744 6.39104C7.19186 6.05073 7.51037 5.76349 7.56535 5.75271C7.62028 5.74192 7.67971 5.73025 7.69739 5.72673C7.71508 5.72321 7.78896 5.74244 7.86158 5.76945Z" fill={color}/>
                </svg>)
};

export default TelephoneIcon;


